import React from 'react'
import PropTypes from 'prop-types'

import loadable from '@loadable/component'

import { useWindowSize } from '../helpers/useWindowSize'

const Heading = loadable(() => import('./UtilityHeading'))
const PortableText = loadable(() => import('./PortableText'))
const CheckList = loadable(() => import('./CheckList'))
const Profile = loadable(() => import('./Profile'))

const SplitPage = ({
  title,
  details,
  reviewId,
  description,
  includes,
  amenities,
  additional,
  coach,
  host,
  children,
}) => {
  const language = process.env.GATSBY_SITE_LANGUAGE
  const windowSize = useWindowSize()
  const hosts = coach || host
  const length = windowSize.width > 640 ? 10 : 6    


  return (
    <section className="container grid grid-cols-12 pt-8 md:pt-16">
      <div className="col-span-12 border-b border-gray-200 xl:col-span-10 xl:col-start-2">
        <div className="relative flex items-stretch">
          <div className="flex-1 l:w-6/12 xl:w-5/12">
            <Heading title={title} details={details} reviewId={reviewId} />
            {description && (
              <div className="py-8 border-t border-gray-200 border-solid lg:py-16">
                <PortableText blocks={description} isBasic />
              </div>
            )}
            {includes && <CheckList title={language === 'en' ? `Included` : `Incluso`} items={includes} />}
            {amenities && (
              <CheckList
                title={language === 'en' ? `Amenities` : `Caratteristiche`}
                items={amenities}
                type="short"
                limit={length}
              />
            )}
            {additional && (
              <div className="py-8 border-t border-gray-200 border-solid lg:py-16">
                <PortableText blocks={additional} isBasic />
              </div>
            )}
            {hosts?.length > 0 && (
              <div className="py-12 border-t border-gray-200 md:py-16">
                <h3 className="mb-4 text-lg font-bold text-black">
                  {coach && `Coach${hosts.length > 1 ? '' : 'es' }`}
                  {host && `Retreat host${hosts.length > 1 ? '' : 's'}`}
                </h3>

                {hosts.map(person => 
                  <Profile key={person._id} person={person} full />
                )}
              </div>
            )}
          </div>
          {windowSize.width > 1024 && (
            <div className="mb-16 flex-0 lg:w-5/12 lg:pl-12">
              <div className="sticky left-0 top-90">{children}</div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default SplitPage

SplitPage.propTypes = {
  title: PropTypes.string,
  details: PropTypes.array,
  reviewId: PropTypes.string,
  description: PropTypes.array,
  includes: PropTypes.array,
  amenities: PropTypes.array,
  additional: PropTypes.array,
  coach: PropTypes.object,
  host: PropTypes.object,
  children: PropTypes.object,
}
